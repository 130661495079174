export const FEATURE_NAME = 'session';

export const ROLES = 'roles';
export const AUTH = 'auth';
export const TWO_FACTOR_AUTH = 'twoFactorEnabled';
export const FORCE_2FA = 'force2FA';
export const LOGGED_IN = 'loggedIn';
export const USER = 'user';
export const PERMISSIONS = 'permissions';

export const TITLE = 'title';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const GENDER = 'gender';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const NEW_PASSWORD = 'nextPassword';
export const IS_ADMIN = 'isAdmin';
export const JOB_TITLE = 'jobTitle';
export const PRODUCT_UPDATES = 'productUpdates';
export const SUBSCRIBED = 'subscribed';

export const POSITION_TITLES = [
  { id: '', label: '-' },
  { id: 'dr', label: 'Dr.' },
  { id: 'drmed', label: 'Dr. med.' },
  { id: 'pd', label: 'PD Dr.' },
  { id: 'pdmed', label: 'PD Dr. med.' },
  { id: 'professor', label: 'Prof.' },
  { id: 'professormed', label: 'Prof. Dr. med.' },
];

export const GENDERS = [
  { id: 'female', label: 'Frau' },
  { id: 'male', label: 'Mann' },
  { id: 'other', label: 'Divers' },
];
