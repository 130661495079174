import React from 'react';

import { PROFILE } from 'utils/routes';
import { useUser, useLoggedIn, TWO_FACTOR_AUTH, FORCE_2FA } from 'store/session';
import { Text } from '../styles';

import { Container, Button } from './styles';

const Warn2FA = () => {
  const isLoggedIn = useLoggedIn();
  const has2FA = useUser(TWO_FACTOR_AUTH);
  const force2FA = useUser(FORCE_2FA);

  return (
    Boolean(isLoggedIn && force2FA && !has2FA) && (
      <Container>
        <Text>Aktivieren Sie die Zwei-Faktor-Authentifizierung für mehr Sicherheit.</Text>
        <Button to={`${PROFILE}#activate2fa`}>Jetzt aktivieren</Button>
      </Container>
    )
  );
};

export default Warn2FA;
